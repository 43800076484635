
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaymentButton",
  props: {
    text: { type: String, required: true },
    enabled: { type: Boolean, default: true },
    icon: { type: String },
    onClick: { type: Function },
    isLoading: { type: Boolean, default: false },
  },
  setup(props: any, ctx: any) {
    const click = async () => {
      if (props.onClick) {
        ctx.emit("onLoading", true);
        await props.onClick();
        ctx.emit("onLoading", false);
      } else {
        ctx.emit("onClick", null);
      }
    };

    return {
      click,
    };
  },
});
