
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  watch,
  computed,
} from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import PaymentButton from "@/components/atomics/PaymentButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { InvoiceRepository, RepositoryFactory } from "@/lib/https";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import Popup from "@/components/popups/Popup.vue";
export default defineComponent({
  components: {
    ValidationTextField,
    // ToggleCheckbox,
    TextButton,
    PaymentButton,
    MenuOptionDropdown,
    Popup,
    FlatButton,
  },
  props: {
    role: { type: Number },
  },
  methods: {
    updateMonth(value: string) {
      const month = parseInt(value);
      if (month > 1 && value.charAt(0) != "0" && month <= 9) {
        this.expireMonth = "0" + value;
      }
      if (month > 12) {
        this.expireMonth = "12";
      }
    },
    onMonthBlur() {
      if (
        this.expireMonth == "1" ||
        this.expireMonth == "0" ||
        this.expireMonth == "00"
      ) {
        this.expireMonth = "01";
      }

      if (!this.checkExpire()) {
        setTimeout(() => {
          this.form.setErrors({
            expireMonth: this.t("errors.errorCardExpired"),
          });
        }, 100);
      }

      this.resetForm();
    },
    onYearBlur() {
      if (!this.checkExpire()) {
        setTimeout(() => {
          this.form.setErrors({
            expireYear: this.t("errors.errorCardExpired"),
          });
        }, 100);
      }

      this.resetForm();
    },
    checkExpire() {
      const checkYear = parseInt(this.expireYear);
      const checkMonth = parseInt(this.expireMonth);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      if (checkYear + 2000 < currentYear || (checkYear + 2000 == currentYear && checkMonth < currentMonth)) {
        return false;
      }
      else {
        return true;
      }
    },
    resetForm() {
      this.form.resetForm({
        values: {
          cardNo: this.cardNo,
          expireYear: this.expireYear,
          expireMonth: this.expireMonth,
          securityCode: this.securityCode,
          cardName: this.cardName,
        },
      });
    },
  },
  setup(props) {
    const state = reactive({
      showInforPopup: false,
      showUpgradePlanPermissionPopup: false,
      companyId: "",
      cardNo: "",
      expireYear: "",
      expireMonth: "",
      securityCode: "",
      cardName: "",
      tokennumber: "",
      messageError: "",
      isLoading: false,
    });
    const { upgradeCompanyToStandardPlan, getPlanInfo } =
      RepositoryFactory.getRepository<InvoiceRepository>(InvoiceRepository);
    const router = useRouter();
    const { t } = useI18n();

    onMounted(async () => {
      // check premium
      const planInfo = await getPlanInfo();
      if (planInfo.planType == 1) {
        router.push({ name: "Dashboard" });
      }

      let script = document.createElement("script");
      const gmoScript = process.env.VUE_APP_GMO_SCRIPT as string;
      const tshop = process.env.VUE_APP_GMO_TSHOP as string;
      script.setAttribute(
        "src",
        gmoScript //test link
      );
      script.onload = (e) => {
        (window as any).Multipayment.init(tshop);
      };
      document.head.appendChild(script);
    });

    watch(
      () => props.role,
      (val) => {
        if (val != 1) {
          state.showUpgradePlanPermissionPopup = true;
        }
      }
    );

    const canclePurchase = () => {
      router.push("/invoice");
    };

    const goBack = () => {
      router.back();
    };

    const doPurchase = async () => {
      state.messageError = "";
      (window as any).Multipayment.getToken(
        {
          cardno: state.cardNo,
          expire: `${state.expireYear}${state.expireMonth}`,
          securitycode: state.securityCode,
          holdername: state.cardName,
          tokennumber: state.tokennumber,
        },
        async (responsePayment: any) => {
          state.isLoading = true;
          if (responsePayment.resultCode != "000") {
            state.messageError = t("payment.errorFailPayment");
          } else {
            const formData = new FormData();
            formData.append("token", responsePayment.tokenObject.token);
            await upgradeCompanyToStandardPlan(formData).catch((e) => {
              state.messageError = e.data.message;
            });
            if (state.messageError == "") {
              router.push("/payment/payment-complete");
            }
          }
          state.isLoading = false;
        }
      );
    };

    const form = useForm();
    const { formHandle } = useFormHandler(form, doPurchase);
    const submit = async function () {
      await formHandle();
    };

    const isFullRequireField = computed(() => {
      const { cardNo, expireYear, expireMonth, securityCode, cardName } = state;
      let expire = false;

      const checkYear = parseInt(expireYear);
      const checkMonth = parseInt(expireMonth);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (currentYear < checkYear + 2000 || (checkYear + 2000 == currentYear && currentMonth <= checkMonth)) {
        expire = true;
      }

      return /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13})$/i.test(cardNo) && expire &&
             /^.{3,4}$/i.test(securityCode) && /^[a-zA-Z ]+$/i.test(cardName);
    });

    return {
      ...toRefs(state),
      canclePurchase,
      doPurchase,
      submit,
      goBack,
      isFullRequireField,
      form,
      t,
    };
  },
});
