import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4069c79e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "loading-indicator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button flat_button", { 'icon-button': _ctx.icon, disabled: !_ctx.enabled || _ctx.isLoading }]),
    disabled: !_ctx.enabled || _ctx.isLoading,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enabled && !_ctx.isLoading ? _ctx.click() : ''))
  }, [
    (_ctx.icon && !_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "icon",
          src: _ctx.icon
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}